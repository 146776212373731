import Links from "./Links";
import Javascript from "./Javascript";
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";

function Services(){
const state={
  responsive:{
      0: {
          items: 1,
      },
      450: {
          items: 1,
      },
      600: {
          items: 2,
      },
      1000: {
          items: 3,
      },
  },
}
  return (
    <> {/* Service Start */}
      <div className="container-xxl rounded border py-5 bg-dark footer">
        <div className="container " >
          <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
            <p className="d-inline-block border rounded fw-bold py-1 px-3" style={{color:"#fbb31b"}}>Our Products</p>
            <h1 className="display-5 mb-5" />
          </div>
          <OwlCarousel className="service-carousel rounded px-2 py-2" responsive={state.responsive} margin={8} autoplay={true} loop  data-wow-delay="0.3s">
            <div className="project-item pe-5 pb-5 mx-2">
              <div className="project-img  mb-5">
                <img className="img-fluid rounded" src="img/products/WATERSACHET.jpg" alt="" style={{ height: '300px' }} />
                <Link to="/watersachet"><i className="fa fa-link fa-3x text-primary" /></Link>
              </div>
              <div className="project-title  mt-2">
                <h4 className="mb-0 " >WATER SACHET PRODUCERS</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 mx-2">
              <div className="project-img mb-5">
                <img className="img-fluid rounded" src="img/products/shoppingoutlets.jpg" alt="" style={{ height: '300px' }} />
                <Link to="shoppingoutlets"><i className="fa fa-link fa-3x text-primary" /></Link>
              </div>
              <div className="project-title ">
                <h4 className="mb-0 ">SHOPPING OUTLETS</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 mx-2">
              <div className="project-img mb-5">
                <img className="img-fluid rounded" src="img/products/black-pallet-wrap-1.jpg" alt="" style={{ height: '300px' }} />
                <Link to="/construction"><i className="fa fa-link fa-3x text-primary" /></Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">CONSTRUCTION INDUSTRY</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 mx-2">
              <div className="project-img mb-5">
                <img className="img-fluid rounded" src="img/products/FOOD&BEVERAGE.jpg" alt="" style={{ height: '300px' }} />
                <Link to="/fandb"><i className="fa fa-link fa-3x text-primary" /></Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">FOOD &amp; BEVERAGE PLANTS</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 mx-2">
              <div className="project-img mb-5">
                <img className="img-fluid rounded" src="img/products/industrialnew.png" alt="" style={{ height: '300px' }} />
                <Link to="/industrial"><i className="fa fa-link fa-3x text-primary" /></Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">INDUSTRIAL CONSUMERS</h4>
              </div>
            </div>
          </OwlCarousel>
        </div>

      </div>
    {/* Service End */ }
    </>
  )
}
export default Services;